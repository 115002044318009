.slider{
    height: 1000px;
    display: flex;
    align-items: end;
    position: relative;
    >.wrapper{
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
    &__inner{
        position: relative;
        display: flex;
        align-items: center;
        padding: 160px 0 0 0;
        gap: 0 25vmin;
        >.news__info{
            display: flex;
            gap: 0 115px;
            flex-grow: 1;
            >.main{
                font-family: 'Gilroy-Medium', sans-serif;
                align-self: center;
                font-weight: 600;
                font-size: 18px;
            }
            >.news__inner{
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 15px 0;
                >.categories{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px 15px;    
                    >a{
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-weight: 700;
                        font-size: 15px;
                    }
                }
                >h1{
                    font-family: 'Gilroy-Medium', sans-serif;
                    font-weight: 700;
                    font-size: 44px;
                }
                >p{
                    font-weight: 600;
                    font-size: 18px;
                }

                >.sliders{
                    display: flex;
                    justify-content: center;
                    // margin-top: 10vmin;
                    align-items: center;
                    gap: 0 5px;
                    order: 2;
                    >p{
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-weight: 600;
                        font-size: 26px;
                        user-select: none;
                    }
                    >img{
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
        .news__dop_info{
            display: flex;
            flex-direction: column;
            gap: 15px 0;
        }
    }
}