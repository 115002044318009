/* Reset and base styles  */
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

// @import url('https://fonts.cdnfonts.com/css/gilroy-bold');

@font-face {
	font-family: 'gilroy';
	font-style: normal;
	font-weight: Bold;
	src: local('Gilroy-Bold'), url(https://fonts.cdnfonts.com/s/16219/Gilroy-Bold.woff) format('woff')
}

@font-face {
	font-family: 'gilroy';
	font-style: normal;
	font-weight: Light;
	src: local('Gilroy-Light'), url(https://fonts.cdnfonts.com/s/16219/Gilroy-Light.woff) format('woff')
}

@font-face {
	font-family: 'gilroy';
	font-style: normal;
	font-weight: Medium;
	src: local('Gilroy-Medium'), url(https://fonts.cdnfonts.com/s/16219/Gilroy-Medium.woff) format('woff')
}

@font-face {
	font-family: 'gilroy';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Regular'), url(https://fonts.cdnfonts.com/s/16219/Gilroy-Regular.woff) format('woff')
}

* {
	padding: 0px;
	margin: 0px;
	border: none;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
	text-decoration: none;
	color: inherit;
}

a:hover {
	text-decoration: none;
}

body {
	font-family: 'SF Pro Display', sans-serif;
	font-weight: 500;
	background: #EDEBE9;
	color: #EDEBE9;
	scroll-behavior: smooth;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
}

ul,
ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img,
svg {
	max-width: 100%;
	height: auto;
}

address {
	font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button,
input[type="submit"] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
	outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}

.container {
	max-width: 95%;
	margin: 0 auto;
}

.title {
	font-weight: 700;
	font-size: 44px;
}

.category__button {
	border: 2px solid rgba(90, 157, 216, 1);
	border-radius: 22px;
	padding: 10px 15px;
}
.hover__button{
	color: white;
	background-color: #5a9dd8;
	border: 1px solid rgba(90, 157, 216, 1);
	width: fit-content;
	padding: 10px 17px;
	border-radius: 22px;
	transition: background-color .5s;
	&:hover{
		background-color: transparent;
	}
}
.hover__button__type2{
	color: white;
	background-color: #5a9dd8;
	border: 1px solid rgba(90, 157, 216, 1);
	width: fit-content;
	padding: 10px 17px;
	border-radius: 10px;
	font-size: 15px;

	transition: background-color .5s;
	&:hover{
		background-color: transparent;
	}
}