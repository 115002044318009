@media(max-width:1500px) {

    // .news__dop_info {
    //     display: none !important;
    // }
    .header__links>.link {
        img {
            width: 60px;
        }
    }

}
@media(max-width:1400px) {  
    .slider__inner .news__dop_info{
        display: none;
    }
}

@media(max-width:1200px) {
    .news, .news_search {
        .card {
            width: calc(50% - 25px) !important;
        }

        .sideBar {
            display: none;
        }

        .recomandation {
            width: calc(50% - 25px) !important;
            flex-grow: unset !important;
            min-height: 425px;
            justify-content: space-between;

            .cards {
                padding-bottom: 10px;
                width: 100%;
                overflow: auto;

                &::-webkit-scrollbar-thumb {
                    background-color: black;
                    border-radius: 50%;
                }

                &::-webkit-scrollbar {
                    width: 2px !important;
                    height: 2px !important;
                }
            }
        }

    }

    .main__inner>.news123>.card>.wrapper>.info>.categories {
        flex-wrap: wrap;
        gap: 8px;
    }

    .YouTube {
        padding:34px 20px 82px;

        h1 {
            font-size: 28px;
        }

        a {
            margin: auto !important;
            width: 202px !important;
            height: 202px !important;
            font-size: 18px;

        }

        .bottom {
            gap: 100px;
            display: flex;
            flex-direction: column;
            width: 90%;
            align-items: center;

            .left {
                width: calc(100% - 50px);
                max-width: 300px;
            }

            .right {
                width: 100%;
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

}

@media(max-width:1000px) {
    .slider {
        &__inner {
            .news__info {
                .main {
                    display: none !important;
                }
            }
        }
    }
    .slider__inner .news__dop_info{
        display: flex;
    }

    .main__inner>.news123>.card>.wrapper>.info>.categories {
        flex-wrap: wrap;
    }

    .main__inner>.top>.filter {
        flex-wrap: wrap;
        gap: 20px;
    }

    .slider__inner>.news__info>.news__inner,
    .slider__inner>.news__info>.news__inner>.categories {
        h1 {
            color: #EDEBE9;
            font-size: 28px;
            font-weight: 700;
        }

        p {
            color: #EDEBE9;
            font-family: SF Pro Display;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.15px;
        }
    }

    .main {
        &__inner {


            >.news123 {
                .info {
                    justify-content: space-between !important;

                }

                >.card {

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        max-width: calc(50% - 17.5px);
                        width: calc(100% - 100px);

                    }

                    // >.wrapper {

                    // }
                }
            }
        }
    }

    .main__inner>.news123>.card>.wrapper>.info .author {
        color: #8A8886;
        font-family: SF Pro Display;
        font-size: 12px;
        font-weight: 500;
    }

    .main__inner>.news123>.card>.wrapper>.info .text__views {
        display: none !important;
    }
    // .header_media .lol{
    //     display: none;
    // }
}

@media(max-width:800px) {
    .slider__inner {
        gap: 0 10vmin;
        
    }
    
}

@media(max-width:600px) {
    .header_media .lol{
        display: flex;
        
    }
    .home {
        h2 {
            font-size: 28px !important;

        }
    }
    .slider__inner .news__dop_info{
        display: none;
    }
    .title {
        margin: 0 !important;
        padding-bottom: 24px;
    }

    .slider__inner {
        gap: 350px;
        flex-direction: column-reverse;

        .news__dop_info {
            align-self: flex-end;
            align-items: end;
        }
    }

    .news, .news_search {
        .card {
            width: calc(100% - 25px) !important;
            margin: auto;
        }

        .recomandation {
            margin: auto;
            width: calc(100% - 25px) !important;

            min-height: 425px;
        }

    }

    .main__inner>.news123 {
        flex-wrap: wrap;

        .card {
            max-width: calc(100%) !important;
            width: calc(100%) !important;

        }
    }

    .ads {
        overflow: hidden;
    }

    .slider__inner>.news__info>.news__inner {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 15px;
        }

        a {
            font-size: 15px;
        }
    }

    .main__inner>.top>.filter {
        .category__button {
            display: none !important;
        }
    }
}