.card{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 17px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc((100% / 4) - 25px);
    &__top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >.views{
            display: flex;
            align-items: center;
            gap: 0 5px;
        }
    }
    &__bottom{
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        >span{
            font-size: 12px;
            font-weight: 500;
        }
    }
}
