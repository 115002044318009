.TV_program {
    color: black;
    padding: 180px 48px 137px 64px;

    h1 {
        font-family: Gilroy;
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 21px;
        .block {
            display: flex;
            gap: 10px;

            .column.colunm_1 {
                width: 200px;
                align-items: start;
                button{
                    margin-top: 62px;
                }
            }
            
            .column.colunm_2 {
                width: 150px;
                align-items: start;
                button{
                    margin-top: 62px;
                }
                >.active{
                    background: #5A9DD8;
                    color: #FFF;
                }
            }

            .column.colunm_3 {
                flex-grow: 1;
                gap: 24px;
                // overflow: auto;
                flex-wrap: wrap;
                // &::-webkit-scrollbar{
                //     display: none;
                // }
            }
            .colunm_3{
                max-width: calc(100% - 550px);
                min-width: calc(100% - 550px);
            }
            .column {
                display: flex;
                align-items: center;
                .Card_programm {
                    min-width: 150px;
                    max-width: 150px;
                    height: 160px;
                    padding: 15px 17px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border-radius: 7px;
                    border: 2px solid #5A9DD8;
                    h2 {
                        font-family: Gilroy;
                        font-size: 26px;
                        font-weight: 600;
                    }

                    p {
                        font-family: SF Pro Display;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }

                button {
                    padding: 9px 17px;
                    color: #000;
                    font-family: Gilroy;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 21.5px;
                    border: 2px solid #5A9DD8;
                }
            }

        }
    }
}
.TV_program.media{
    display: none;
}

@media(max-width:1000px){
    .TV_program{
        display: none;
        padding: 20px;
    }
    .TV_program.media{
        display: block;
        .main{
            .block{
                flex-direction: column;
                .colunm_2{
                    overflow: auto;
                    width: 100%;
                    gap: 10px;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
                .colunm_3{
                    max-width: calc(100% );
                    min-width: calc(100% );
                    .Card_programm{
                        max-width: 150px!important;
                        min-width: 150px!important;
                    }
                }
            }
        }
    }
}