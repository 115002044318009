.d-flex{
    display: flex;
    align-items: center;
}
.g-pointer{
    gap: 0 5px;
    cursor: pointer;
}
.news{
    padding-top: 50px;
    &__inner{
        >.title{
            color: #323130;
            font-family: Gilroy sans-serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
        }
        >.cards{
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            margin-top: 20px;
            justify-content: space-between;
            >.recomandation{
                background-image: url('../../../../assets/images/bg_news.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center bottom;
                border-radius: 18px;
                padding: 25px 45px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 100%;
                >.cards{
                    display: flex;
                    gap: 0 58px;
                    align-items: center;
                    >.recomandation__card{
                        display: flex;
                        flex-direction: column;
                        gap: 11px 0;
                        >img{
                            max-width: 135px;
                            height: 135px;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }
                }
                >.text{
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    gap: 15px 0;
                    margin-bottom: 60px;
                    >span{
                        font-family: Gilroy;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 600;
                    }
                    >p{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}