.Dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.background {
  background-image: url('https://www.fonstola.ru/images/202409/www.fonstola.ru.1725766998.3119.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.message {
  color: white;
  font-size: 3rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); // Полупрозрачный фон для текста
  padding: 20px;
  border-radius: 10px;
}