    .DetailNews {
        padding-left: 20px;
        color: black;
        margin-top: 200px;
        // display: flex;
        width: 100%;
        position: relative;

        .main__right {
            width: calc(100% - 100px);
            max-width: 892px;

        }

        .nawigation {
            color: #8A8886;
            font-family: SF Pro Display;
            font-size: 15px;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 5px;

            a {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        h1.c1 {
            font-family: Gilroy;
            font-size: 26px;
            font-weight: 700;
        }

        .sideBar {
            position: absolute;
        }

        .past_sideBar {
            width: calc(100% - 100px);
            display: flex;
            justify-content: end;
            gap: 100px;
        }

        p {
            color: #000;
            font-family: SF Pro Display;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
        }

        .main__right {
            // margin: auto;
            display: flex;
            align-items: start;
            flex-direction: column;

            h1 {
                color: #000;
                font-family: sans-serif;
                font-size: 44px;
                font-style: normal;
                font-weight: 700;
            }

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 32px;
                flex-shrink: 0;
            }

            .viuve {
                display: flex;

                p.c2 {
                    font-weight: 500;
                    margin-left: 5px;
                }

                p {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    color: #8A8886;
                    font-family: SF Pro Display;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                }

                .btn__block {
                    display: flex;
                    align-items: center;
                    margin-left: 65px;
                    gap: 16px;

                    a {
                        color: #000;
                        font-family: Gilroy;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        padding: 10px 17px;
                        border-radius: 21.5px;
                        border: 2px solid #5A9DD8;
                    }
                }


            }

            .Share {
                color: white;
                border-radius: 8px;
                background: #5A9DD8;
                display: flex;
                padding: 21px 19px;
                align-items: center;
                justify-content: space-between;
                width: 832px;
                margin-top: 23px;

                p {
                    color: #FFF;
                    font-family: SF Pro Display;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    color: white;

                }

                .right {
                    display: flex;
                    align-items: center;
                    gap: 14px;

                    .massage {
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        color: #FFF;
                        font-family: SF Pro Display;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        svg path{
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    .slick-arrow,
    .slick-prev {
        display: none !important;
    }

    .news_main {
        margin-top: 36px;
        margin-bottom: 60px;

        .card {
            width: 90%;

            * {
                color: white;

            }
        }
    }

    @media(max-width:1600px) {
        .DetailNews {
            .past_sideBar{
                gap: 50px;
            }
            .main__right {
                max-width: 800px;
            }
            .dangerouslySetInnerHTML__p{
                width: 100%;
            }
        }
    }
    @media(max-width:1500px) {
        .DetailNews {
            .past_sideBar{
                gap: 50px;
            }
            .main__right {
                max-width: 600px;
            }
        }
    }
    @media(max-width:1400px) {
        .DetailNews {
            .past_sideBar{
                justify-content: center;
            }
            .main__right {
                max-width: 892px;
            }
            .WeAdd{
                display: none;
            }
        }
    }

    @media(max-width:1400px) and (min-width:1000px) {
        .DetailNews .main__right {
            position: relative;
            left: 100px;
        }
    }

    @media(min-width:1000px) {
        .media {
            display: none !important;
        }
    }

    @media(max-width:1000px) {
        .lol.media {
            display: none;
        }

        .DetailNews {
            padding: 17px;

            h1 {
                font-size: 28px !important;
                font-weight: 700 !important;
            }

            p {
                font-family: SF Pro Display;
                font-size: 15px;
                font-weight: 500;
                /* 200% */
            }

            .sideBar,
            .btn__block {
                display: none !important;
            }

            .Share {
                max-width: 832px;
                width: 100% !important;

                .right {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .past_sideBar {
                width: 100%;
            }

            .main__right {
                margin: auto;
                width: calc(100% - 5px);
            }

            .news_main {
                .lol {
                    display: none;
                }

                .lol.media {
                    flex-wrap: wrap;
                    display: flex;
                    row-gap: 30px;
                    column-gap: 6px;
                    justify-content: space-between;
                    align-items: center;

                    .card {
                        width: calc(50% - 15px);
                    }
                }
            }
        }
    }

    @media (max-width:620px) {
        .Share {
            flex-direction: column;
            gap: 25px 0;
            font-size: 26px;

            .right {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    @media(max-width:550px) {
        .DetailNews {
            padding: 25px;

            .sideBar {
                display: none !important;
                // position: relative;
                // display: flex;
                // flex-wrap: wrap;
                // button{
                //     width: calc(50% - 20px); 
                //     color: #686868;
                //     font-family: SF Pro Display;
                //     font-size: 15px;
                //     font-weight: 500;
                // }
            }

            .news_main {
                .lol.media {
                    justify-content: center;

                    .card {
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }