@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    position: fixed;
    z-index: 3;
    width: 100%;

    >.wrapper {
        border-radius: 14px;
        position: sticky;
        top: 30px;
        background-color: #002053;
        width: 95%;
        color: #EDEBE9;
    }

    &__inner {
        display: flex;
        align-items: center;
        padding: 15px 0;
        justify-content: space-between;
    }

    &__links {
        display: flex;
        align-items: center;
        gap: 0 30px;

        >.link {
            font-size: 15px;

            &-has_arrow {
                position: relative;
                display: block;
                padding-right: 5px;

                &::after {
                    content: '';
                    position: absolute;
                    right: -7px;
                    top: 20%;
                    width: 6px;
                    height: 12px;
                    background-image: url('../../assets/icons/right-arrow.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 0 15px;

        >a {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        >.date__btn {
            gap: 0 8px;
            padding: 12px 20px;
            color: #323130;
            background-color: #EDEBE9;
            border-radius: 6px;
            font-size: 13px;
        }

        >.yt__btn {
            gap: 0 8px;
            padding: 12px 20px;
            color: #EDEBE9;
            background-color: #FF0000;
            border-radius: 6px;
            padding: 15px 45px;
            border-radius: 10px;
            font-size: 15px;
        }
    }
}

.header_media {
    display: none;
}


@media(max-width:1400px) {
    .closed {
        display: none;
    }

    .header_media.active {
        padding-top: 0 !important;
        width: 100% !important;
        top: 0 !important;
        background-color: white !important;

        .wrapper {
            background-color: #00000000 !important;

            .header__links {
              
                a {
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    .header {
        display: none;
    }

    .header_media {
        display: flex;
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        .lol{
            width: 95%;
            display: flex;
            justify-content: end;
            padding-top: 7px;
            flex-wrap: wrap;
            gap: 10px;

        }
        .header__buttons {
            button {
                flex-wrap: wrap;
                width: 41px;
                height: 41px;
                border-radius: 7px;
                background: #5A9DD8;
                gap: 11px;
            }
        }

        .open {
            // position: fixed;
            right: 0;
            top: 100px;
            width: 200px;

            &.closed {
                display: none;
            }
        }

        .open.c1 {
            right: 220px;
        }

        .nav_burger {
            top: 76px;
            position: fixed;
            width: 100%;
            left: 0;
            height: 100%;
            background-color: white;
            color: black;
            display: flex;
            justify-content: space-between;
            padding: 26px 26px 100px;

            // align-items: end;
            .left {
                overflow: auto;
                padding: 0px 10px 0px 0;
                display: flex;
                flex-direction: column;
                gap: 28px;

                // height: fit-content;
                a {
                    color: #000;
                    font-family: SF Pro Display;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .date__btn {
                    padding: 11px 18px;
                    font-size: 13px;
                    background: #EDEBE9;
                    border-radius: 6px;
                    color: black;
                    justify-content: center;
                    display: flex;
                    width: fit-content;
                    align-items: center;
                    gap: 8px;
                }

                .youtube {
                    border-radius: 10px;
                    background: #F00;
                    padding: 14px 50px;
                    color: #EDEBE9;
                    font-family: SF Pro Display;
                    font-size: 15px;
                    font-weight: 500;
                    display: flex;
                    gap: 5px;
                }
            }

            .right {
                gap: 12px;
                display: flex;
                // flex-direction: column;
                color: white;
                align-items: end;
                margin: auto 0 0 0;
            }
        }
    }
}

@media(max-width:600px) {
    .header_media {
        .nav_burger {
            flex-direction: column;
            overflow: auto;

            .left {
                overflow: unset;
                align-items: start;
                padding: 0;
                margin-bottom: 15px;
                gap: 15px;
            }

            .right {
                flex-direction: column;
                align-items: start;
                justify-content: start;
            }
        }
    }
}

.slick-track {
    display: flex;
    gap: 50px;
}


.basic__search{
    width: 140px;
    height: 20px;
    border-radius: 18px;
    text-align: center;
}

.search__button{
    width: 41px;
    height: 41px;
    border-radius: 7px;
    background: #5A9DD8;
    padding: 5px;
}

.search__div{
    display: flex;
    border: solid 1px #fff;
    border-radius: 18px;
}