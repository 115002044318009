
.main{
    &__inner{
        padding: 50px 0;
        >.top{
            color: rgba(50, 49, 48, 1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            >.filter{
                display: flex; 
                align-items: center;
                gap: 0 20px;
                >span{
                    font-size: 12px;
                    color: rgba(138, 136, 134, 1);
                    cursor: pointer;
                }
            }
        }
        >.news123{
            display: flex;
            gap:25px 35px;
            flex-wrap: wrap;
            justify-content: space-between;
            >.card{
                display: block;
                background-image: url('../../../../assets/images/kitay__bg.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 17px;
                position: relative;
                >.wrapper{
                    background-size: cover;
                    position: absolute;
                    background-repeat: no-repeat;

                    background-color: rgba(0, 0, 0, 0.5); 
                    background-blend-mode: overlay; /* Смешивание фонового изображения с цветом фона */
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 17px;
                    padding: 30px 37px 28px 30px;
                }
                .mini_title{
                    color: #EDEBE9;
                    font-family: 'Gilroy';
                    font-size: 26px;
                    font-style: normal;
                    font-weight: bold;
                    line-height: normal;
                }
                &:nth-child(1){
                    max-width: calc(60% - (35px/2));
                    width: calc(100% - 100px);
                    >.wrapper{
                        >.info{
                            justify-content: flex-end;
                            >.categories{
                                display: flex;
                                margin-bottom: 15px;
                            }
                            >.text{
                                h2{
                                    // width: 480px;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2){
                    max-width: calc(40% - (35px/2));
                    width: calc(100% - 100px);
                    // max-width: 38%;
                    // width: 100%;
                    h2{
                        // width: 450px;
                    }
                    .categories{
                        gap: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                    }
                }
                &:nth-child(3),
                &:nth-child(4){
                    max-width: calc(50% - (35px/2));
                    width: calc(100% - 100px);
                    // max-width: 49%;
                    // width: 100%;
                    h2{
                        // width: 450px;
                    }
                }
                >.wrapper>.info{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    >.categories{
                        display: flex;
                        column-gap: 15px;
                    }
                    .author{
                        margin-top: 11px;
                        display: flex;
                        opacity: .9;
                        align-items: center;
                        gap: 9px;
                        hr{
                            width: 1px;
                            height: 23px;
                            background-color: white;
                            border: none;
                        }
                    }
                    .text__views{
                        margin-top: -20px;
                        width: 100%;
                        justify-content: end;
                        display: flex;
                        gap: 9px;
                        // opacity: .5;
                        align-items: center;
                    }
                }
            }
        }
    }
}