.news_search {
    padding-top: 160px;
    padding-bottom: 55px;


    &__inner2 {
        display: flex;
    }

    &__content {
        flex-grow: 1;
        padding-bottom: 55px;

        >.cards {
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            >.card {
                padding: 35px;
                width: calc((100% / 3) - 25px);
                &__top {
                    margin-bottom: 200px;
                }
            }
        }
    }
}
.search{
    margin-bottom: 40px;
    display: flex;
    align-items: baseline;
    >input{
        height: 20px;
        background-color: #EDEBE9;
        border-bottom: 1px  #000 solid;
        color: #0e0e0e;
        padding: 15px 5px;
        font-size: 26px;
    }
    >.title {
        margin-top: 30px;
        color: #000;
    }
}
.last {
    border-radius: 17px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    >p {
        color: #FFF;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 22px;
    }
}

@media (max-width:520px) {
    .search{
        flex-wrap: wrap;
    }
}