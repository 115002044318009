.Footer {
    color: black;
    display: flex;
    padding: 21px;

    .main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block {
            display: flex;
            gap: 100px;
            align-items: inherit;
        }

        .icon {
            width: 91px;

            img {
                width: 100%;
                filter: invert(1);
            }
        }

        .nav {
            display: flex;
            flex-direction: column;
            gap: 24px;

            a {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .contact {
            font-family: SF Pro Display;
            font-size: 26px;
            display: flex;
            flex-direction: column;
            gap: 32px;
            justify-content: center;

            span {
                color: #5A9DD8;
            }

            .top {
                justify-content: center;
                display: flex;
                align-items: center;
                gap: 22px;
            }
        }

        .map::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.292) 0%, rgba(13, 0, 17, 0) 100%);
            border-radius: 23px;
            z-index: 0;
        }

        .map {
            border-radius: 23px;
            position: relative;
            width: 245px;
            height: 415px;
            display: flex;
            justify-content: center;
            align-items: end;
            padding: 21px;
            background-image: url(../../assets/images/map.png);
            background-size: cover;

            div {
                position: relative;
                z-index: 1;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 16px;
                width: 100%;

                h3 {
                    font-family: SF Pro Display;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.18px;
                }
            }

            a {
                color: white;
            }
        }

        .life {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .hover__button__type2 {
                color: white;
                display: flex;
                gap: 5px;
                padding: 11px 26px;
            }

            .date__btn {
                margin: auto;
                padding: 11px 18px;
                font-size: 13px;
                background-color: white;
                border-radius: 6px;
                color: black;
                justify-content: center;
                display: flex;
                width: fit-content;
                align-items: center;
                gap: 8px;
            }

            .life__bottom {
                display: flex;
                flex-direction: column;
                padding: 10px 10px 26px 10px;
                gap: 7px;
                text-align: center;
                color: #4B4B4B;
                text-align: center;
                font-family: SF Pro Display;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;

                border-radius: 13px;
                border: 2px solid #4B4B4B;

            }

        }
    }
}

.footer_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;

    a {
        border-radius: 10px;
        background: #000;
        padding: 18px 20px 15px;
        color: #FFF;
        text-align: center;
        font-family: SF Pro Display;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}



@media(max-width:1200px) {
    .Footer {
        .main {
            flex-wrap: wrap;
            justify-content: start;
            gap: 20px;

            .block {
                width: calc(50% - 10px);
            }

            .icon {
                order: 2;
            }

            .nav {
                order: 1;
            }

            // .nav,.icon{
            //     width: calc(50% - 10px);
            //     max-width: calc(100% - 10px);
            // }
            // .contact,.map,.life,.block{
            //     width: calc(50% - 30px);
            //     // max-width: 160px;
            //     .life__bottom{
            //         img{
            //             width: 100px;
            //         }
            //     }
            // }
        }
    }

    .footer_bottom {

        .logo,
        .right {
            display: none !important;
        }
    }
}

@media(max-width:1200px) {
    .Footer {
        .main {
            .block {
                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 4;
                }

                &:nth-child(4) {
                    order: 3;
                }

                width: calc(100% - 40px);
            }
        }
    }

    .footer_bottom {

        .logo,
        .right {
            display: none !important;
        }
    }
}