.currency{
    background-color: rgba(24, 30, 40, 1);
    border-radius: 14px;
    padding: 15px 20px;
    font-family: 'Gilroy-Medium', sans-serif;
    &__inner{
        display: flex;
        flex-direction: column  ;
        gap: 6px 0;
        >span{
            font-weight: 600;
            font-size: 16px;
        }
        >hr{
            width: 100%;
            height: 1px;
            background-color: rgba(138, 136, 134, 1);
        }
        >.currencier{
            max-width: 160px;
            width: 100%;
            >.currency_one{
                font-weight: 500;
                font-size: 14px;
                >.card__curr{
                    display: flex;
                    flex-direction: column;
                    gap: 5px 0;
                    margin: 5px 0;
                    >.title{
                        display: flex;
                        gap: 0 7px;
                        font-weight: 500;
                        font-size: 14px;
                        >img{
                            width: 30px;
                        }
                    }
                    >.info{
                        display: flex;
                        align-items: center;
                        gap: 0 20px ;
                        >p{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}