.Publication {
    color: black;
    padding: 180px 48px 137px 64px;

    .main {
        width: calc(100%);
        max-width: 990px;
        margin: auto;
        display: flex;
        flex-direction: column;

        h1 {
            font-family: Gilroy;
            font-size: 44px;
            font-weight: 700;
        }

        h2.c2 {
            margin-top: 65px;
            margin-bottom: 18px;
        }

        h2 {
            margin-top: 17px;
            font-family: Gilroy;
            font-size: 26px;
            font-weight: 700;
        }

        .download {
            margin-top: 50px;
            color: #FFF;
            font-family: SF Pro Display;
            font-size: 15px;
            font-weight: 500;
            border-radius: 10px;
            background: #5A9DD8;
            padding: 12px 50px;
            width: fit-content;
        }

        .block {
            width: calc(100%);

            max-width: 800px;
            display: flex;
            flex-direction: column;
            margin-top: 31px;



            .left {
                width: 100%;
                display: flex;
                gap: 17px;

                p {
                    width: calc(50% - 10px);
                    font-family: SF Pro Display;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 24px;
                }
            }

            .right {
                width: 100%;
                display: flex;
                gap: 17px;

                img {
                    border-radius: 10px;
                    border: 1px solid #AFAFAF;
                    width: calc(50% - 10px);

                }
            }
        }

        .block_bottom {
            max-width: 800px;
            width: calc(100%);

            display: flex;
            gap: 25px;

            .left {
                padding: 27px;
                width: 50%;
                border-radius: 22px;
                border: 3px solid #5A9DD8;

                img {
                    height: 240px;
                    width: 100%;
                    object-fit: 10px 10px;
                    border-radius: 15px;
                    margin-bottom: 17px;
                }

                .block_bottom {
                    display: flex;

                    .block_bottom_left {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        a {
                            display: flex;
                            gap: 12px;
                            font-family: SF Pro Display;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }

                    .block_bottom_right {
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        align-items: end;

                        p {
                            font-family: SF Pro Display;
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .right {
                width: 50%;
            }
        }
    }
}

@media (max-width:1000px) {
    .Publication {
        padding: 26px !important;

        .main {
            .block {
                display: flex;
                flex-direction: row-reverse;
                gap: 68px;

                .left,
                .right {
                    flex-direction: column;

                    img,
                    p {
                        width: 100%;
                    }
                }
            }
        }

        .block_bottom {
            flex-direction: row-reverse;

            .left {
                padding: 25px !important;

                img {
                    height: auto !important;
                }
            }

            .block_bottom {
                flex-direction: column;
            }

            .block_bottom_right {
                align-items: start !important;
            }

            .left,
            .right {
                width: 100%;
            }
        }
    }
}

@media (max-width:500px) {
    .Publication {
        h1 {
            font-family: Gilroy;
            font-size: 28px;
            font-weight: 700;
        }

        .main {
            .block {
                flex-direction: column;

                img {
                    width: 70% !important;
                }
            }
        }

        .block_bottom {
            flex-direction: column;

            .left,
            .right {
                width: 100% !important;
            }
        }
    }
}