.sideBar{
    max-width: 200px;
    color: #000;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-right: 15px;
    >h2{
        margin-bottom: 15px;
    }
    >.sidebar__btn{
        color: #686868;
        font-size: 15px;
        font-weight: 500;
        transition: all .5s;
        margin: 10px 0;
        display: block;
        &:hover{
            color: #000;
        }
    }
    
    >.active{
        color: #000;
        border-radius: 7px;
        border: 1px solid #000;
        padding: 10px 21px;
        margin-left: -21px;
    }
}