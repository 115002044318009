.news {
    padding-top: 160px;
    padding-bottom: 55px;

    >.container>.title {
        margin-bottom: 40px;
        margin-top: 30px;
        color: #000;
        margin-left: 200px;
    }

    &__inner2 {
        display: flex;
    }

    &__content {
        flex-grow: 1;
        flex-wrap: wrap;
        padding-bottom: 55px;

        >.cards {
            display: flex;
            flex-wrap: wrap;
            gap: 25px;

            >.card {
                padding: 35px;
                width: calc((100% / 3) - 25px);

                &__top {
                    margin-bottom: 200px;
                }
            }
        }
    }
}

.has__hr {
    border-bottom: 1px solid#000;
}

.hasn_pad {
    padding: 0 !important;
}

.last {
    border-radius: 17px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    >p {
        color: #FFF;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 22px;
    }
}

