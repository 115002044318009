.YouTube {
  .scrollable-container {
    width: 600px;
    height: 50%;
  }
  .main__video {
    width: 50%;
    height: 500px;
    >iframe{
    border-radius: 30px;

    }
    >h2{
      margin-top: 5px;
      font-size: 22px;
    }
  }
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 30px;
  padding: 80px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../assets/images/gord.png);
  backdrop-filter: brightness(40%);
  background-size: cover;
  background-position: center bottom;

  h1 {
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;
    color: #edebe9;
    font-family: Gilroy;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
      width: 100px;
    }
  }

  a {
    margin: 120px auto;
    display: flex;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #edebe9;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.4);

    svg {
      min-width: 27px;
      max-width: 27px;
    }
  }

  .bottom {
    .left {
      p {
        max-width: 1140px;
        width: 95%;
        margin: auto;
        margin-top: 3px;
        color: #edebe9;
        font-family: SF Pro Display;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: absolute;
      right: 50px;
      bottom: 100px;

      .block {
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #edebe9;
        width: 215px;
        color: #edebe9;
        font-family: SF Pro Display;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 600px) {
  .YouTube h1 svg {
    width: 50px;
  }
}

.scrollable-container {
  width: 300px;
  height: 200px;
  overflow: auto;
  &-active{
    display: none;}
}
@media (max-width:1300px) {
  .YouTube{
    display: flex;
    justify-content: flex-start !important;
  }
  .scrollable-container,.main__video{
    display: none;
    &-active{
      width: 95% !important;
      padding: 0 25px;
      display: block;
      >.scrollable-content{
        display: flex;
        gap: 0 15px;
        width: 100%;
        >iframe{
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
.scrollable-content {
  padding: 10px;
  height: 500px;
  >iframe{

    border-radius: 30px;
  }
}
