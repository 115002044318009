.About{
    padding: 180px 0 50px;
    color: black;
    max-width: 990px;
    width: calc(100% - 100px);
    margin: auto;
    h1{
        font-family: SF Pro Display;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
    }
    p{
        max-width: 672px;
        width: 100%;
        color: #000;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }
    img{
        width: 100%;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: calc(100%);
        max-width: 895px;
    }
    .p{
        max-width: 100%;
    }
}
@media(max-width:400px){
    .About{
        img{
            width: 80%;
        }
    }
}