@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.ads {
    // background-image: ;
    background: url('../../../../assets/images/add-bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    color: #EDEBE9;
    align-items: center;
    padding: 50px;

    .media {
        display: none;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        // width: calc(100% - 50px);
        // max-width: 1500px;

        .block {
            font-family: SF Pro Display;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;

            .nav {
                align-items: center;
                display: flex;
                gap: 13px;

                hr {
                    height: 33px;
                    width: 1px;
                    background-color: #fff;
                    border: none;
                }
            }
        }

        .block.c1 {
            gap: 40px;
        }

        .block.c2 {
            a {
                color: #000;
                font-family: SF Pro Display;
                font-size: 18px;
                font-weight: 600;
                display: flex;
                padding: 10px 28px;
                align-items: flex-start;
                gap: 4px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.35);
            }
        }

        .block.c3 {
            gap: 30px;

            .main {
                display: flex;
                gap: 18px;
                >a{
                    width: 22px;
                    height: 22px;
                }

            }
        }

    }
}

@media (max-width:1550px) {
    .ads {

        .container {
            gap: 20px;

            .block {
                .nav {
                    font-size: 20px;
                    gap: 6px;

                    hr {
                        height: 17px;
                    }
                }

            }

            .block.c1 {
                gap: 20px;

                svg {
                    width: 55px;
                    max-width: 55px;

                }
            }

            .block.c2 {
                a {

                    font-size: 16px;
                    padding: 6px 14px;
                    border-radius: 5px;

                }
            }

            .block.c3 {
                .main {

                    gap: 10px;
                }
            }

            .block.c4 {
                img {

                    width: 100px;
                }
            }
        }
    }
}

@media (max-width:1250px) {
    .nav{
        display: none !important;
    }
    .ads {  
        .media {
            display: flex !important;
            color: #FFF;
            font-family: SF Pro Display;
            font-size: 24px;
            font-weight: 600;
        }
    }
}

@media (max-width:850px) {
    .ads {  
        gap: 10px;
        .media {
            display: flex;
            color: #FFF;
            font-family: SF Pro Display;
            font-size: 24px;
            font-weight: 600;
        }

        .container {
            .block.c1 {
                svg {
                    width: 38.25px;
                    height: 36.752px;
                }

                .nav {
                    flex-wrap: wrap;
                    justify-content: center;

                    hr {
                        display: none;
                    }

                    a {
                        font-size: 8px;
                        padding: 2.5px;
                        border-radius: 2.588px;
                        border: 1px solid #696969;
                    }
                }
            }

            .block.c2 {
                a {
                    padding: 3px 10px;
                    font-size: 16px;
                }
            }

            .block.c3 {
                gap: 10px;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: center;


                .main {
                    gap: 35px;
                    margin-bottom: 5px;
                    >a{
                        >svg{
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }

            .block.c4 {
                display: none;
            }
        }
    }
}

@media (max-width:500px) {
    .ads {  
        padding: 25px;
        .media{
            font-size: 20px;
        }
        .container {
            .block.c2 {
                display: none;
            }
            .block.c3 {
                .main {
                    gap: 15px;
                }
            }

        }
    }
}