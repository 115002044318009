.InfoGraf {
    width: 100%;
    padding-top: 120px;

    .main {
        .left {
            .main__inner {
                display: flex;
                // flex-direction: column;
                justify-content: center;
                // align-items: center;
                flex-wrap: wrap;
                // padding-right: 340px;
                gap: 20px;

                .top {
                    width: calc(100% - 90px);
                }

                .news123 {
                    gap: 20px;
                    width: calc(100% - 440px);

                    .card:nth-child(1),
                    .card:nth-child(2),
                    .card:nth-child(3),
                    .card:nth-child(4) {
                        max-width: unset;
                        width: unset;
                    }

                    .card.c1 {
                        width: calc(50% - 120px);
                    }

                    .card.c2 {
                        width: calc(50% + 100px);
                    }

                    .card.c3 {
                        width: calc(50% + 100px);
                    }

                    .card.c4 {
                        width: calc(50% - 120px);
                    }
                }
            }
        }
    }
}

@media(max-width:1400px) {
    .InfoGraf {
        .main__inner {

            .top,
            .news123 {
                width: calc(100% - 40px) !important;

            }

            .WeAdd {
                display: none;
            }
        }
    }
}

@media(max-width:1200px) {
    .InfoGraf {
        .main__inner>.news123>.card .mini_title {
            font-size: 20px;
        }

        .main .left .main__inner .news123 .card {
            height: 300px;
        }
        .main__inner > .news123 > .card > .wrapper > .info .text__views{
            display: flex !important;
            color: #8a8886;
            img{
                width: 20px;
                filter: contrast(0.5);
            }
        }
    }
}
@media(max-width:900px) {
    .InfoGraf {
        .main .left .main__inner .news123 .card:nth-child(1) {
            width: 100%;
        }

        .main .left .main__inner .news123 .card:nth-child(2) {
            width: 100%;
        }

        .main .left .main__inner .news123 .card:nth-child(3) {
            width: 100%;
        }

        .main .left .main__inner .news123 .card:nth-child(4) {
            width: 100%;
        }
    }
}