.weather{
    min-width: 160px;
    min-height: 193px;
    border-radius: 14px;
    padding: 16px 12px;
    background: linear-gradient(90deg, rgba(61,133,177,1) 0%, rgba(0,212,255,1) 100%);
    font-weight: 500;
    font-size: 12px;
    .h3{
        font-size: 15px;    
    }
    &__inner{
        >.weather_one{
            >div{
                display: flex;
                align-items: center;
                gap: 0 5px;
                >p{
                    display: flex;
                    align-items: center;    
                    // font-size: 15px;
                    > img{
                        margin-right: 2px;
                    }
                }
                >img{
                    width: 44px;
                    height: 44px;
                }
            }
            >p{
                text-transform: capitalize;
                // font-size: 14px;
                margin-bottom: 5px;
            }
        }
    }
}