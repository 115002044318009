.WeAdd {
    position: sticky;
    top: 200px;
    padding: 31px 30px 30px 26px;
    height: fit-content;
    width: 320px;
    background-image: url(../../assets/images/WeAddBg.png);
    background-size: cover;
    background-position: center;
    border-radius: 18px;

    .top {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        padding-bottom: 67px;

        a {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #EDEBE9;
            font-family: 'Gilroy';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            svg{
                width: 20px;
            }
        }
    }

    .center {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 80px;

        h2 {

            color: #FFF;
            font-family: SF Pro Display;
            font-size: 22.16px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 7px;
        }

        a {
            padding: 5.18px;
            color: #FFF;
            font-family: SF Pro Display;
            font-size: 16.56px;
            font-style: normal;
            font-weight: 500;
            border-radius: 5.175px;
            border: 0.517px solid #696969;
        }
    }

    .bott {
        display: flex;
        justify-content: center;
        a {
            color: #000;
            font-family: SF Pro Display;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 10px;
            background: #FFF;
            padding: 10px 28px;

            box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.35);
        }
    }
}