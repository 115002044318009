.Add {
    width: 100%;
    height: 400px;
    background-color: rgb(173, 173, 173);
    display: flex;
    justify-content: end;
    padding: 50px;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    margin: 100px 0;
    .massegers {
        max-width: 1400px;
        width: calc(100% - 30px);
        display: flex;
        justify-content: center;
        // align-items: center;
        gap: 50px;
        a svg{
                width: 34px;
                height: 34px;
                path{
                    fill: rgb(90, 157, 216);
                }
            }
    }
    
    .site {
        display: flex;
        justify-content: center;
        a {
            border-radius: 10px;
            background: #5A9DD8;
            color: #FFF;
            font-family: SF Pro Display;
            font-size: 15px;
            font-weight: 500;
            padding: 11px 20px;
            
        }
    }
}