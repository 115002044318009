.gallery{
    padding-top: 150px;
    &__inner{
        >h1{
            color: #000;
        }
        >.images{
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            >img{
                width: calc(50% - 20px);
                border-radius: 30px;
            }
        }
    }
}
@media (max-width:750px) {
    .images{
        margin: 10px 0 !important;
        >img{
            width: 100% !important;
        }
    }
}